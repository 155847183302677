<template>
  <v-container>
    <v-sheet class="pa-4">
      <div class="text-h4">
        Thank You, Sea2Sea.
      </div>
      <div class="text-h5 mt-3">
        <br>
        <div class="text-h6">
          This is to those who know when and not to give up.
          <br>
          Don't stop because it gets uncomfortable or hard.
          <br>
          Stop when you have nothing left to give.
          <br>
          Don't follow your dreams, Make them a reality.
        </div>
        <br>
        <div>
          Thank you,
        </div>
        <div>
          Matthew Maggio
        </div>
        <div class="h6">
          PADI # (PADI Pro Number will go here)
        </div>
      </div>
    </v-sheet>
  </v-container>
</template>

<script>
export default {
  name: 'ThankYou',
  created () {
    this.doSetDocumentTitle('Thank you from Matthew Maggio', true)
  }
}
</script>

<style scoped>

</style>
